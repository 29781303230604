<template>
  <a
    :href="href"
    :target="target"
    @click="clickHandler($event)"
    :class="{ 'has-button': hasButton }"
    class="cs-link">
      <slot>{{ title }}</slot>
  </a>
</template>

<script>

export default {
  name: 'cs-link',
  props: {
    link: null, // string or router object { path, hash, params, query, [title] } or kirby object
    replace: { // use $router.replace() instead of $router.push()
      type: Boolean,
      default: false
    },
    scrollOptions: {} // scroll options for anchor links
  },
  computed: {
    href () {
      if (fn.isObject(this.link)) {

        // kirby object
        if (fn.has(this.link, 'type')) {
          switch (this.link.type) {
            case 'node':
              if (fn.has(this.link, 'redirect')) {
                return this.link.redirect
              } else {
                return this.link.value
              }
            case 'item':
            case 'extern':
              return this.link.value
            case 'email':
            case 'file':
            case 'tel':
              return this.link.value
          }
        }

        // router object https://router.vuejs.org/guide/essentials/navigation.html
        else if (this.isRouterLink) {
          var route = this.$router.resolve(this.link)
          return route.href || '/'
        }
      }

      // extern url
      else if (fn.isUrl(this.link)) {
        return this.link
      }

      // email
      else if (fn.isEmail(this.link)) {
        return 'mailto:' + this.link
      }
      return null
    },
    target () {
      if ((fn.isObject(this.link) && this.link.type === 'extern') || fn.isUrl(this.link)) {
        return '_blank'
      }
      return null
    },
    title () {
      if (fn.isObject(this.link)) {
        return this.link.title
      } else if (fn.isEmail(this.link)) {
        return this.link
      }
      return this.href
    },
    anchor () {
      if (fn.isObject(this.link) && this.link.type === 'anchor') {
        return this.link.value
      }
      return null
    },
    isKirbyLink () {
      return fn.isString(this.link) ||
        (fn.isObject(this.link) && (this.link.type === 'node' || this.link.type === 'item'))
    },
    isRouterLink () {
      return fn.has(this.link, 'path') ||
        fn.has(this.link, 'hash') ||
        fn.has(this.link, 'params') ||
        fn.has(this.link, 'query')
    },
    hasButton () {
      if(this.$slots.default && this.$slots.default[0].componentOptions) {
        return this.$slots.default[0].componentOptions.tag === 'cs-button'
      }
      return false
    }
  },
  methods: {
    clickHandler (Event) {
      Event.stopPropagation()
      if (this.isRouterLink) {
        Event.preventDefault()
        this.goToRoute(this.link)
      } else if (this.isKirbyLink) {
        Event.preventDefault()
        this.goToRoute(this.href)
      }
      this.$emit('click', Event, this.link)
    },
    goToRoute (target) {
      if (this.replace) {
        this.$router.replace(target).catch(err => {})
      } else {
        this.$router.push(target).catch(err => {})
      }
    }
  }
}
</script>

<style lang="sass">
.cs-link
  cursor: pointer
  &.has-button
    text-decoration: none !important
    cursor: default
</style>  