<template>
  <component
    v-if="hasContent"
    :is="component"
    class="cs-text">
      <template v-for="(block, i) in paragraphs">
        <component
          :is="htmlBlock(block)"
          :key="i"
          :class="block.cssclass">
        </component>
      </template>
  </component>
</template>

<script>
export default {
  name: 'cs-text',

  /**
   * Text may be
   * - Object (see below)
   * - a single copy field 
   * - a single string field
   * - other field types (not implemented yet)
   * - a string
   * - an array with all elements mixed
   */
  props: {
    content: {
      default: ''
    },
    component: {
      type: String,
      default: 'div'
    }
  },
  computed: {
    hasContent () {
      return (fn.isArray(this.content) && this.content.length > 0) ||
        (fn.isString(this.content) && this.content !== '')
    },

    // Convert given fields to flat array of html-blocks
    paragraphs () {
      var res = []
      var content = fn.isArray(this.content) ? this.content : [ this.content ]
      fn.each(content, (section) => {

        // simply a string
        if (fn.isString(section)) {
          res.push({
            tag: 'p',
            html: section
          })
          return
        }

        // a field
        if (fn.isObject(section) && fn.has(section, 'tag') && fn.has(section, 'html')) {
          res = res.concat(section)
          return
        }

        // sections
        // object with fields an additional data for css-classes
        // {
        //  headline: [field],
        //  subheadline: [field],
        //  copy: [field],
        //  cssclass: [field],
        //  isteaser: [field]
        // }
        var cssClass = []
        if (fn.has(section, 'cssclass') && fn.isString(section.cssclass)) {
          cssClass.push(section.cssclass)
        }
        if (fn.has(section, 'headline') && fn.isString(section.headline)) {
          res.push({
            tag: 'h1',
            html: section.headline,
            cssclass: cssClass.join(' ')
          })
        }
        
        if (fn.has(section, 'subheadline') && fn.isString(section.subheadline)) {
          res.push({
            tag: 'h2',
            html: section.subheadline,
            cssclass: cssClass.join(' ')
          })
        }

        if (fn.has(section, 'copy')) {
          if (fn.has(section, 'isteaser') && fn.isTrue(section.isteaser)) {
            cssClass.push('is-teaser')
          }
          if (fn.isArray(section.copy)) {
            fn.each(section.copy, (block) => {
              block.cssclass = cssClass.join(' ')
              res.push(block)
            })
          }
        }
      })
      return res
    }
  },
  methods: {
     htmlBlock (block) {
      if (block.tag === 'hr') {
        return { template: '<hr />' }
      }
      if (!block.html) { // just to be sure
        return { template: '' }
      }
      let regex = /^<code>.*<\/code>$/i
      if (fn.isString(block.html) && regex.test(block.html)) {
        return { template: '<pre>' + block.html + '</pre>' }
      }
      return { template: '<' + block.tag + '>' + block.html + '</' + block.tag + '>' }
    }
  }
}
</script>

<style lang="sass">
</style>