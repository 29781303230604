<template>
  <div class="cs-sections">
    <template v-for="(section, i) in sections">

      <!-- Headlines, Copy -->
      <cs-row
        v-if="isText(section)"
        :key="key(i)"
        class="section copy">
          <cs-text
            :content="section.text"
            component="cs-col"
            :class="[ colClass ]"
            class="text" />
      </cs-row>

      <!-- Images -->
      <cs-row
        v-if="isImages(section)"
        :key="key(i)"
        :class="[ colClass, imagesClass(section) ]"
        class="section images is-box">
          <cs-col>
            <cs-images
              :files="section.images"
              :size="imagesSize(section)"
              :display="section.display"
              :zoom="section.zoom"
              :autoplay="section.autoplay"
              :ranges="section.ranges"
              :speed="section.speed"
              :oneRowXS="section.onerowmobile"
              :pagination="true"
              :title="imagesTitle(section)"
              paginationPosition="inside" />
          </cs-col>
      </cs-row>

      <!-- Links -->
      <cs-row
        v-else-if="isLinks(section)"
        :key="key(i)"
        :class="[ colClass ]"
        class="section links"> 
          <cs-col>
             <cs-links
              :links="section.links"
              :class="{
                'is-list': section.display === 'list',
                'is-buttons': section.display === 'buttons'
              }"
              :linkClass="linkClass(section)" />
          </cs-col>
      </cs-row>

      <!-- Video -->
      <cs-row
        v-else-if="isVideo(section)"
        :key="key(i)"
        :class="[ colClass ]"
        class="section video">
          <cs-col class="is-8 is-offset-2">
            <video-elem
              :source="section.source"
              :video="section.video" />
          </cs-col>
      </cs-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'cs-sections',
  props: {
    content: {
      type: Array,
      default: () => {
        return []
      }
    },
    colClass: String
  },
  data () {
    return {}
  },
  computed: {
    sections () {
      var res = []
      var textKey = null
      fn.each(this.content, (section, key) => {
        
        // put h1 (h2) and copy together
        if (this.isHeadline(section) || this.isCopy(section)) {
          if (textKey !== null) {
            res[textKey].text.push(section)
          } else {
            res.push({
              fieldset: 'text',
              text: [ section ]
            })
            textKey = res.length - 1
          }
        }

        // transform links
        if (this.isLinks(section)) {
          var links = []
          fn.each(section.links, (entry) => {
            links.push(entry.link)
          })
          if (links.length > 0) {
            var newSection = fn.clone(section) // to keep all addtional fields like "display"
            newSection.links = links
            res.push(newSection)
            textKey = null
          }
        }

        // the rest
        else {
          res.push(section)
          textKey = null
        }
      })
      return res
    }
  },
  methods: {
    key (...keys) {
      return keys.join('-')
    },

    // original sections
    isHeadline (section) {
      return section.fieldset === 'headline'
    },
    isCopy (section) {
      return section.fieldset === 'copy'
    },
    isImages (section) {
      return section.fieldset === 'images' && this.hasImages(section)
    },
    isLinks (section) {
      return section.fieldset === 'links'
    },
    isVideo (section) {
      return section.fieldset === 'video'
    },

    // grouped sections
    isText (section) {
      return section.fieldset === 'text'
    },

    hasImages (section) {
      return fn.isArray(section.images) && section.images.length > 0
    },
    imagesSize (section) {
      return section.display === 'slider' ? 'sections.banner' : 'sections.row'
    },
    imagesTitle (section) {
      if (fn.has(section, 'subheadline') && fn.isString(section.subheadline)) {
        return section.subheadline
      }
      return ''
    },
    sectionClass (section) {
      if (fn.has(section, 'cssclass') && fn.isString(section.cssclass)) {
        return section.cssclass
      }
    },
    imagesClass (section) {
      var res = this.sectionClass(section)
      if (section.display === 'slider') {
        res += ' is-slider'
      }
      return res
    },
    linkClass (section) {
      if (section.display === 'buttons') {
        return 'button is-outline'
      }
    }
  }
}
</script>

<style lang="sass">
.cs-sections
  .section
    margin-top: m(2)
    &:first-child
      margin-top: 0
    &.images
      .col
        padding: col-gap()
        background-color: grey(8)
      &.is-slider
        .col
          padding-left: 0
          padding-right: 0
          background-color: transparent
    &.links
      ul
        &.is-buttons
          display: flex
          justify-content: center
          flex-wrap: wrap
          li
            margin: m(1)
        &.is-list
          list-style: disc inside none
          margin-left: m(2)

+md
  .cs-sections
    .section
      &.images
        .col
          padding: col-gap('md')

+sm
  .cs-sections
    .section
      margin-top: m(3)
      &:first-child
        margin-top: 0
      &.images
        .col
          padding: col-gap('sm')

+xs
  .cs-sections
    .section
      margin-top: m(2)
      &.images
        .col
          padding: col-gap('xs')
</style>