<template>
  <component
    :is="component"
    :style="style"
    :attrs="attrs"
    @transitionend="onTransitionEnd($event)"
    class="cs-slide"
    ref="container">
      <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'cs-slide',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 500,
    },
    component: {
      type: String,
      default: 'div',
    }
  },
  data: () => ({
    style: {},
    initial: false,
    hidden: false,
  }),
  created() {
    this.hidden = !this.active
  },
  mounted() {
    this.layout()
    this.initial = true
  },
  computed: {
    el() {
      return this.$refs.container
    },
    attrs() {
      return {
        'aria-hidden': !this.active,
        'aria-expanded': this.active,
      }
    },
  },
  methods: {
    layout() {
      if (this.active) {
        this.hidden = false
        this.$emit('openstart')
        if (this.initial) {
          this.setHeight('0px', () => this.el.scrollHeight + 'px')
        }
      } else {
        this.$emit('closestart')
        this.setHeight(this.el.scrollHeight + 'px', () => '0px')
      }
    },

    asap(callback) {
      if (!this.initial) {
        callback()
      } else {
        this.$nextTick(callback)
      }
    },

    setHeight(temp, afterRelayout) {
      this.style = { height: temp }

      this.asap(() => {
        // force relayout so the animation will run
        this.__ = this.el.scrollHeight

        this.style = {
          height: afterRelayout(),
          overflow: 'hidden',
          'transition-property': 'height',
          'transition-duration': this.duration + 'ms',
        }
      })
    },

    onTransitionEnd(Event) {
      // Don't do anything if the transition doesn't belong to the container
      if (Event.target !== this.el) return

      if (this.active) {
        this.style = {}
        this.$emit('openend')
      } else {
        this.style = {
          height: '0',
          overflow: 'hidden',
        }
        this.hidden = true
        this.$emit('closeend')
      }
    },
  },
  watch: {
    active() {
      this.layout()
    },
  }
}
</script>

<style lang="sass">
</style>