<template>
  <div
    class="cs-base">
      <transition name="fade" mode="out-in">
        <component
          :is="currentTemplate"
          :key="currentKey"
          class="cs-template">
        </component>
      </transition>
  </div>
</template>

<script>

/**
 * Base Template is the universal entry point for ALL pages
 * It loades the page-specific template which then again loades the page
 */

// eslint-disable-next-line
import styles from '@/styles/main.sass'

export default {
  name: 'cs-default',
  components: {},
  computed: {
    currentTemplate () {
      return this.$router.getTemplate()
    },
    currentKey () {
      return 'cs-template-' + this.$router.getBlueprint()
    }
  }
}
</script>

<style lang="sass">
.cs-base
  z-index: $z-index-base
  .page
    padding-top: 0
    padding-bottom: 0
    transition: all .4s cubic-bezier(.55,0,.1,1)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity .5s ease
    &.fade-enter,
    &.fade-leave-active
      opacity: 0
</style>
