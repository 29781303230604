<template>
  <svg
    class="cs-svg"
    :viewBox="viewBox"
    :style="style"
    :class="name"
    role="img">
      <title v-if="$slots.default"><slot></slot></title>
      <use :xlink:href="href"></use>
  </svg>
</template>

<script>
export default {
  name: 'cs-svg',
  props: {
    name: {
      type: String,
      default: ''
    },
    color: { // set color like #234abc direclty instead with class
      type: String,
      default: ''
    }
  },
  computed: {
    href () {
      return '#' + this.name
    },
    viewBox () {
      return this.$image.getViewBox(this.name)
    },
    style () {
      if (fn.isString(this.color) && this.color.substr(0, 1) === '#') {
        return 'fill:' + this.color + ';'
      }
      return ''
    }
  }
}
</script>

<style lang="sass">

  // every color-class or fill is ignored, when svg has fill-attribute
  // (which is intended to have multi-colored svgs)
  .cs-svg
    height: auto
    fill-rule: evenodd
    clip-rule: evenodd
    stroke-linejoin: round
    stroke-miterlimit: 1.41421
    fill: grey(5)
    &.is-primary
      fill: color('primary')
    &.is-secondary
      fill: color('secondary')
    &.is-warning
      fill: color('warning')
    &.is-success
      fill: color('success')
    &.is-white
      fill: white()
</style>
