<template>
  <ul
    :class="elemClass"
    class="cs-links"
    ref="links">
      <li
        v-for="(link, i) in links"
        :key="i"
        :class="itemClass(link)"
        @mouseenter="mouseEnterHandler($event, link)"
        @mouseleave="mouseLeaveHandler($event, link)"
        class="item">
          <cs-link
            :link="link"
            @click="clickHandler($event, link)"
            :class="linkClass"
            :scrollOptions="scrollOptions">
              {{ link.title }}
          </cs-link>
          <cs-links
            v-if="hasChildren(link)"
            :links="link.children"
            :maxLevel="maxLevel"
            :currentLevel="currentLevel + 1"
            :dropdownStatus="dropdownStatus"
            :class="{ 'is-left': dropdownPosition === 'left', 'is-right': dropdownPosition === 'right' }"
            :scrollOptions="scrollOptions"
            @mouseenter="mouseEnterHandler"
            @mouseleave="mouseLeaveHandler"
            @click="clickHandler">
          </cs-links>
      </li>
  </ul>
</template>

<script>
export default {
  name: 'cs-links',
  props: {
    links: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxLevel: {
      type: Number,
      default: 1
    },
    currentLevel: {
      type: Number,
      default: 1
    },
    dropdownStatus: {
      type: Object,
      default () {
        return {}
      }
    },
    dropdownPosition : {
      type: String,
      default: 'left'
    },
    linkClass: {
      type: String,
      default: ''
    },
    scrollOptions: {} // scroll options for anchor links
  },
  computed: {
    elemClass () {
      return 'level-' + this.currentLevel
    }
  },
  methods: {
    isNavigationLink (link) {
      return link.type === 'node' || link.type === 'item'
    },
    isCollection (link) {
      return link.type === 'collection'
    },
    hasChildren (link) {
      return this.isCollection(link) && this.currentLevel < this.maxLevel && link.children && link.children.length > 0
    },
    itemClass (link) {
      var res =  ['is-' + link.type]
      if (link.home) {
        res.push('is-home')
      }
      if ((this.isNavigationLink(link) || this.isCollection(link)) && fn.has(link, 'value')) {
        res = res.concat(this.$dom.getActiveClasses(this.$route, link.value))
      }
      if (this.hasChildren(link)) {
        res.push('has-dropdown')
      }
      if (this.isCollection(link) && this.dropdownStatus[link.id]) {
        res.push('is-open')
      }
      if (fn.has(link, 'cssclass') && fn.isString(link.cssclass)) {
        res.push(link.cssclass)
      }
      return res.join(' ')
    },
    getWidth () { // used by parent
      return this.$dom.getWidth(this.$refs.links)
    },
    mouseEnterHandler (Event, link) {
      Event.stopPropagation()
      this.$emit('mouseenter', Event, link)
    },
    mouseLeaveHandler (Event, link) {
      Event.stopPropagation()
      this.$emit('mouseleave', Event, link)
    },
    clickHandler (Event, link) {
      this.$emit('click', Event, link)
    }
  }
}
</script>

<style lang="sass">
// Minimum style for classes is-vertical and is-horizontal
// usually styled by parent component
.cs-links
  &.is-vertical
    .level-2
      .item
        margin-left: m(3)
  &.is-horizontal
    display: flex
    .item
      margin-right: m(1)
      &:last-child
        margin-right: 0
    .level-2
      display: none
</style>