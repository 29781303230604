<template>
  <component
    :is="component"
    class="cs-col">
      <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'cs-col',
  props: {
    'component': {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style lang="sass">
.cs-col
  display: block
  flex-grow: 1
  flex-shrink: 1
  padding: calc(col-gap() / 2)
  @for $i from 1 through $col-count
    &.is-#{$i}
      flex: none
      width: percentage(calc($i / $col-count))
    &.is-offset-#{$i}
      margin-left: percentage(calc($i / $col-count))
  @for $i from 1 through 10
    @for $j from 1 through $i
      &.is-#{$j}-from-#{$i}
        flex: none
        width: percentage(calc((10 / $i * $j) / 10))
      &.is-offset-#{$j}-from-#{$i}
        margin-left: percentage(calc((10 / $i * $j) / 10))
.cs-row
  &.no-gap
    >.cs-col
      padding: 0
    &.is-first
      >.cs-col
        padding-top: calc(col-gap()/2)
    &.is-last
      >.cs-col
        padding-bottom: calc(col-gap()/2)

+md
  .cs-col
    padding: calc(col-gap('md') / 2)
    @for $i from 1 through $col-count
      &.is-#{$i}-md
        flex: none
        width: percentage(calc($i / $col-count))
      &.is-offset-#{$i}-md
        margin-left: percentage(calc($i / $col-count))
    @for $i from 1 through 10
      @for $j from 1 through $i
        &.is-#{$j}-from-#{$i}-md
          flex: none
          width: percentage(calc((10 / $i * $j) / 10))
        &.is-offset-#{$j}-from-#{$i}-md
          margin-left: percentage(calc((10 / $i * $j) / 10))
    &.is-no-offset-md
      margin-left: 0
  .cs-row
    &.no-gap,
    &.no-gap-md
      >.cs-col
        padding: 0
      &.is-first
        >.cs-col
          padding-top: calc(col-gap('md')/2)
      &.is-last
        >.cs-col
          padding-bottom: calc(col-gap('md')/2)

+sm
  .cs-col
    padding: calc(col-gap('sm') / 2)
    @for $i from 1 through $col-count
      &.is-#{$i}-sm
        flex: none
        width: percentage(calc($i / $col-count))
      &.is-offset-#{$i}-sm
        margin-left: percentage(calc($i / $col-count))
    @for $i from 1 through 10
      @for $j from 1 through $i
        &.is-#{$j}-from-#{$i}-sm
          flex: none
          width: percentage(calc((10 / $i * $j) / 10))
        &.is-offset-#{$j}-from-#{$i}-sm
          margin-left: percentage(calc((10 / $i * $j) / 10))
    &.is-no-offset-sm
      margin-left: 0
  .cs-row
    &.no-gap,
    &.no-gap-sm
      >.cs-col
        padding: 0
      &.is-first
        >.cs-col
          padding-top: calc(col-gap('sm')/2)
      &.is-last
        >.cs-col
          padding-bottom: calc(col-gap('sm')/2)

+xs
  .cs-col
    padding: calc(col-gap('xs') / 2)
    &.is-no-offset-xs
      margin-left: 0
  .cs-row
    &:not(.is-xs)
      .cs-col
        margin-left: 0
        width: 100%
      &.no-gap,
      &.no-gap-xs
        >.cs-col
          padding: 0
        &.is-first
          >.cs-col:first-child
            padding-top: calc(col-gap('xs')/2)
        &.is-last
          >.cs-col:last-child
            padding-bottom: calc(col-gap('xs')/2)
    &.is-xs
      .cs-col
        @for $i from 1 through $col-count
          &.is-#{$i}-xs
            width: percentage(calc($i / $col-count))
          &.is-offset-#{$i}-xs
            margin-left: percentage(calc($i / $col-count))
        @for $i from 1 through 10
          @for $j from 1 through $i
            &.is-#{$j}-from-#{$i}-xs
              flex: none
              width: percentage(calc((10 / $i * $j) / 10))
            &.is-offset-#{$j}-from-#{$i}-xs
              margin-left: percentage(calc((10 / $i * $j) / 10))
        &.is-no-offset-xs
          margin-left: 0
      &.no-gap,
      &.no-gap-xs
        >.cs-col
          padding: 0
        &.is-first
          >.cs-col
            padding-top: calc(col-gap('xs')/2)
        &.is-last
          >.cs-col
            padding-bottom: calc(col-gap('xs')/2)
</style>
