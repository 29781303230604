<template>
  <component
    :is="component"
    class="cs-segment">
      <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'cs-segment',
  props: {
    'component': {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style lang="sass">
.cs-segment
  // fullwidth, no padding or margin on default
</style>
