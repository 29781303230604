<template>
  <component
    :is="node"
    class="cs-button"
    :class="[iconClass, disabledClass, spinnerClass]"
    @click="clickHandler($event)">
      <slot></slot>
      <cs-spinner v-if="spinner"></cs-spinner>
  </component>
</template>

<script>
export default {
  name: 'cs-button',
  props: {
    icon: {
      type: String,
      default: ''
    },
    node: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spinner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass () {
      if (fn.isString(this.icon)) {
        var cssClass = [
          'icon-' + this.icon
        ]
        if (!fn.isArray(this.$slots.default)) {
          cssClass.push('icon-only')
        }
        return cssClass.join(' ')
      } else {
        return ''
      }
    },
    disabledClass () {
      return this.disabled || this.spinner ? 'is-disabled' : ''
    },
    spinnerClass () {
      return this.spinner ? 'has-spinner' : ''
    }
  },
  methods: {
    clickHandler (Event) {
      if (!this.spinner && !this.disabled) {
        this.$emit('click', Event)
      }
    }
  }
}
</script>

<style lang="sass">
.cs-button
  
  // base settings
  position: relative
  display: inline-flex
  align-items: center
  justify-content: center
  flex-direction: row
  width: auto
  +font('semibold')
  +ellipsis
  border: 1px solid
  border-radius: $radius-small
  text-transform: uppercase
  cursor: pointer
  +button-size('default')
  &:before
    padding: 0 .2em 0 0
  &.pull-right
    flex-direction: row-reverse
    &:before
      padding: 0 0 0 .2em

  // colors
  &:not(.is-text)
    +button-color(white(), grey(5))
    &.is-primary
      +button-color(white(), color('primary'))
    &.is-secondary
      +button-color(white(), color('secondary'))
    &.is-warning
      +button-color(white(), color('warning'))
    &.is-success
      +button-color(white(), color('success'))
    &.is-white
      +button-color(grey(5), white())
      &:not(.is-icon)
        &:before
          font-size: font-size('default') * 0.8
    &.has-spinner // set by button prop "spinner = true"
      color: transparent !important
      &:before
        color: transparent !important
      .cs-spinner
        display: inline-block
        position: absolute
        left: 50%
        top: 50%
        width: 60px
        margin-left: -30px
        margin-top: -10px
        animation-delay: .2s
        div
          background-color: white()
  &.is-text
    +button-color(color('text'), transparent)
    &.is-primary
      +button-color(color('primary'), transparent)
    &.is-secondary
      +button-color(color('secondary'), transparent)
    &.is-warning
      +button-color(color('warning'), transparent)
    &.is-success
      +button-color(color('success'), transparent)
    &.is-white
      +button-color(white(), transparent)

  // sizes
  &.is-small
    +button-size('small')
  &.is-medium
    +button-size('medium')
  &.is-big
    +button-size('big')

  // fixes
  &.move-down
    &:before
      padding-top: .2em
  &.move-up
    &:before
      padding-bottom: .2em
  &.move-left
    &:before
      padding-right: .3em
  &.move-right
    &:before
      padding-left: .3em
</style>